// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

// Custom styles for the TimelineItem
import { timelineItem, timelineItemIcon } from "examples/Timeline/TimelineItem/styles";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Button, Grid } from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClassIcon from '@mui/icons-material/Class';
import TopicIcon from '@mui/icons-material/Topic';
import ArchiveIcon from '@mui/icons-material/Archive';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

function TimelineItem({ color, title, dateTime, time, docClass, docType, description, recipient, badge, lastItem, handleClick }) {
  const isDark = false;

  return (
    <SoftBox position="relative" sx={(theme) => timelineItem(theme, { lastItem })}>
      <SoftBox
        bgColor={isDark ? "dark" : "white"}
        width="1.625rem"
        height="1.625rem"
        borderRadius="50%"
        position="absolute"
        top="3.25%"
        left="2px"
        zIndex={2}
      >
        {/* <Icon sx={(theme) => timelineItemIcon(theme, { color })}>{icon}</Icon> */}
        <FiberManualRecordIcon sx={(theme) => timelineItemIcon(theme, { color })} />
      </SoftBox>
      <Box ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0}>
        <SoftBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <SoftBox>
            <SoftTypography variant="h5" fontWeight="medium" color={isDark ? "white" : "dark"}>
              {title}
            </SoftTypography>
            <SoftBox mt={1}>
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                color={isDark ? "secondary" : "text"}
                mr={3}
              >
                <CalendarMonthIcon /> {dateTime}
              </SoftTypography>
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                color={isDark ? "secondary" : "text"}
                mr={3}
              >
                <AccessAlarmIcon /> {time}
              </SoftTypography>
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                color={isDark ? "secondary" : "text"}
                mr={3}
              >
                <ClassIcon /> {docClass}
              </SoftTypography>
              {recipient ? (
                <SoftTypography
                  variant="caption"
                  fontWeight="medium"
                  color={isDark ? "secondary" : "text"}
                  mr={3}
                >
                  <ArchiveIcon /> {recipient}
                </SoftTypography>
              ) : null}
              <SoftTypography
                variant="caption"
                fontWeight="medium"
                color={isDark ? "secondary" : "text"}
                mr={3}
              >
                <TopicIcon /> {docType}
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={2} mb={1.5}>
              {description ? (
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  {description}
                </SoftTypography>
              ) : null}
            </SoftBox>
            <SoftBox display="flex" pb={lastItem ? 1 : 2}>
              <SoftBadge color={color} size="xs" badgeContent={badge} container />
            </SoftBox>
          </SoftBox>
          <SoftBox mx={4} sx={{minWidth: "120px"}}>
            { badge == "Public" ? (
              <Button 
              onClick={handleClick}
              color="secondary"
              sx={{
                mt: "auto",
                mr: "auto",
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: `translate(2px, -0.5px)`,
                  transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                },

                "&:hover .material-icons-round, &:focus  .material-icons-round": {
                  transform: `translate(6px, -0.5px)`,
                },
                "&:hover": {
                  transform: "scale(1.00)",
                },
              }}
            >
              View Detail
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </Button>
            ) : (
              <Button 
                disabled
                color="secondary"
                sx={{
                  mt: "auto",
                  mr: "auto",
                  display: "inline-flex",
                  alignItems: "center",
                  cursor: "pointer",

                  "& .material-icons-round": {
                    fontSize: "1.125rem",
                    transform: `translate(2px, -0.5px)`,
                    transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                  },

                  "&:hover .material-icons-round, &:focus  .material-icons-round": {
                    transform: `translate(6px, -0.5px)`,
                  },
                  "&:hover": {
                    transform: "scale(1.00)",
                  },
                }}
              >
                View Detail
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </Button>
            ) }
          </SoftBox>
        </SoftBox>
      </Box>
    </SoftBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  badges: [],
  lastItem: false,
  description: "",
  time: "",
  docClass: "",
  recipient: "",
  docType: "",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  docClass: PropTypes.string.isRequired,
  docType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  recipient: PropTypes.string,
  badge: PropTypes.string.isRequired,
  lastItem: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default TimelineItem;
