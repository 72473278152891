import { Grid } from "@mui/material";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FileView from './FileView';
import SoftBox from "components/SoftBox";

function Filings() {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={2} mb={1}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FileView />
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Filings;