import { Avatar, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Icon, IconButton, Input, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, MenuItem, Pagination, Select, Table, TableContainer, TablePagination, TextField, Typography } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import TimelineItem from "examples/Timeline/TimelineItem";
import { Fragment, useEffect, useState } from "react";
import SoftTypography from "components/SoftTypography";
import { useLocation, useSearchParams } from 'react-router-dom';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import TableViewIcon from '@mui/icons-material/TableView';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import HtmlIcon from '@mui/icons-material/Html';
import TextFieldsIcon from '@mui/icons-material/TextFields';

const TIMEOUT_DURATION = 10000;

function FileView() {
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(100);
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [query, setQuery] = useState('');
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [fileItems, setFileItems] = useState([]);
    const [fileCount, setFileCount] = useState(0);
    const [accession, setAccession] = useState('');

    let [searchParams, setSearchParams] = useSearchParams();
    //const history = useHistory();

    const renderItems = 
        items.length > 0
            ? items.map((item, key) => {
                const itemKey = `item-${key}`;
                const date = new Date(item.createdAt);
                const time = `0${date.getHours()}`.slice(-2) + ':' + `0${date.getMinutes()}`.slice(-2);
                return (
                    <TimelineItem
                        key={itemKey}
                        handleClick={() => handleClickOpen(item.accession, item.fileName)}
                        color={item.securityLevel == "Public" ? "info" : "warning"}
                        title={item.author.role_org}
                        dateTime={item.filed}
                        time={time}
                        docClass={item.documentClass}
                        docType={item.documentType}
                        description={item.description}
                        recipient={item.recipient.role_org}
                        badge={item.securityLevel}
                    />
                );
            })
            : null;
    
    const renderFileItems = 
        fileItems.length > 0
            ? fileItems.map ((fileItem, key) => {
                const fileItemKey = `fileItem-${key}`;
                const fileName = fileItem.split('/');
                const lastName = fileName[fileName.length - 1];
                const fileType = lastName.slice(((lastName.lastIndexOf(".") - 1) >>> 0) + 2);
                
                let typeIcon = <InsertDriveFileIcon />
                if (fileType == "doc" || fileType == 'docx' || fileType == "rtf")
                    typeIcon = <ArticleIcon />
                else if (fileType == "pdf")
                    typeIcon = <PictureAsPdfIcon />
                else if (fileType == "xls" || fileType == "xlsx")
                    typeIcon = <TableViewIcon />
                else if (fileType == "html")
                    typeIcon = <HtmlIcon />
                else if (fileType == 'txt')
                    typeIcon = <TextFieldsIcon />
                else if (fileType == "png" || fileType == "jpg" || fileType == "tif")
                    typeIcon = <ImageIcon />

                return (
                    <ListItem disableGutters key={fileItemKey} sx={{paddingLeft: "0px"}}>
                        <ListItemButton onClick={ () => handleFileClick(fileItem)} sx={{paddingLeft: "0px"}}>
                            <ListItemIcon sx={{minWidth: "30px"}}>
                                {typeIcon}
                            </ListItemIcon>
                            <Typography variant="button">{lastName}</Typography>
                        </ListItemButton>
                    </ListItem>
                );
            }) 
            : null;
    
    const handleFileClick = (filePath) => {
        setOpen(false);
        window.open(filePath, "_blank");
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleClickOpen = (accession, fileName) => {
        if (Array.isArray(fileName)) {
            if (fileName.length == 1) {
                let filePath = `/ferc-files/${accession}_${fileName[0]}`;
                window.open(filePath, "_blank");
            } else if (fileName.length > 1) {
                let filePaths = [];
                fileName.map( (item) => {
                    const filePath = `/ferc-files/${accession}_${item}`;
                    filePaths.push(filePath);
                } );
                setFileItems(filePaths);
                setFileCount(filePaths.length);
                setAccession(accession);
                setOpen(true);
            }
        }
        else {
            let filePath = `/ferc-files/${accession}_${fileName}`;
            window.open(filePath, "_blank");
        }
    };

    const handleSearch = () => {
        const inputElement = document.getElementById('search');
        setQuery(inputElement.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    }

    const handleChangePage = (event, value) => {
        setPage(value);
    }

    const fetchData = async () => {
        setLoading(true);
        
        let url = `/api/data?page=${page}&per_page=${rowsPerPage}`;
        if (query) {
            url += `&query=${query}`;
        }

        const fetchPromise = fetch(url).then(response => {
            if (response.ok) {
                setError(false);
                return response.json();
            } else {
                setError(true);
                console.log('API server is currently unavailable.');
                throw new Error('API server is currently unavailable.');
            }
        });
        const timeoutPromise = new Promise((resolve, reject) => {
            setTimeout(() => {
                reject(new Error('Request timed out'));
            }, TIMEOUT_DURATION);
        });

        try {
            const data = await Promise.race([fetchPromise, timeoutPromise]);
            setTotalCount(data.totalCnt);
            setItems(data.results);
            setError(false);
        } catch (error) {
            console.log('An error occurred:', error.message);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    const setUrl = () => {
        let params = {};
        if (query)  
            params["search"] = query;
        if (page != 1)  
            params["page"] = page;
        if (rowsPerPage != 25)
            params["per_page"] = rowsPerPage;
        setSearchParams(params);
        fetchData();
    }

    useEffect(() => {
        let params = new URL(document.location).searchParams;
        let page = params.get("page");
        let per_page = params.get("per_page");
        let search = params.get("search");

        if (page)
            setPage(parseInt(page));
        if (per_page)
            setRowsPerPage(per_page);
        if (search) {
            setQuery(search);
            document.getElementById("search").value = search;
        }
    }, []);

    useEffect(() => {
        setUrl();
    }, [page, rowsPerPage, query])

    useEffect(() => {
        if (location.search == '') {
            setPage(1);
            setRowsPerPage(25);
            setQuery('');
            document.getElementById("search").value = '';
        }
    }, [location])

    return (
        <Card>
            <Dialog onClose={handleClose} open={open}>
                <Card>
                    <SoftBox display="flex" justifyContent="space-between" flexDirection="column" alignItems="left" p={3}>
                        <SoftBox>
                            <SoftTypography variant="h5" gutterBottom>
                                Ferc File List
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" alignItems="center" lineHeight={0}>
                            <SoftTypography variant="caption" fontWeight="regular" color="text">
                                {fileCount} enties for accession number {accession}
                            </SoftTypography>
                        </SoftBox>
                        <Divider />
                        <SoftBox>
                            <List sx={{pt:0}}>
                                {renderFileItems}
                            </List>
                        </SoftBox>
                    </SoftBox>
                </Card>
            </Dialog>
            <SoftBox minHeight={750} p={3} sx={{ display: 'flex', flexDirection: "column", justifyContent: 'space-between'}}>
                <SoftBox>
                    <Grid container spacing={3}>
                        <Grid container xs={6} md={4} lg={3} item alignItems={"center"}>
                            <Select
                                sx={{maxWidth:"80px"}}
                                id="select"
                                value={rowsPerPage}
                                onChange={handleChangeRowsPerPage}
                            >
                                <MenuItem value={10} > 10 </MenuItem>
                                <MenuItem value={25} > 25 </MenuItem>
                                <MenuItem value={50} > 50 </MenuItem>
                                <MenuItem value={100} > 100 </MenuItem>
                            </Select>
                            <SoftTypography variant="caption" ml={1}> entries per page </SoftTypography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={8}>                    
                            <SoftInput
                                id="search"
                                placeholder="Search"
                                icon={{ component: "search", direction: "left"}}
                                onKeyPress={handleKeyPress}
                            />
                        </Grid>
                        <Grid item xs={12} md={2} lg={1}>
                            <SoftButton variant="contained" color="info" fullWidth onClick={handleSearch}>Search</SoftButton>
                        </Grid>
                    </Grid>
                    { !loading && !error && 
                    <SoftBox p={2} mb={3}>
                        { renderItems }
                    </SoftBox>
                    }
                </SoftBox>
                { loading && 
                <SoftBox p={2} mb={3} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <CircularProgress color="info" />
                </SoftBox>}
                { error && !loading && 
                <SoftBox p={2} mb={3} sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    An error occurred while fetching the data. Please try again later.
                </SoftBox>}
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <SoftTypography variant="caption" mt={1}>Showing {(page-1)*rowsPerPage+1} to {page*rowsPerPage>totalCount?totalCount:page*rowsPerPage} of {totalCount} entries</SoftTypography>
                    <Pagination 
                        defaultPage={1}
                        page={page} 
                        size={'small'} 
                        count={Math.ceil(totalCount / rowsPerPage)} 
                        showFirstButton 
                        showLastButton 
                        onChange={handleChangePage}
                    />
                </Grid>
            </SoftBox>
        </Card>
    );
}

export default FileView;