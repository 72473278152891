
// Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Filings from "layouts/naturalgas/filings";

// Icons
import Shop from "examples/Icons/Shop";
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  { type: "title", title: "Natural Gas", key: "natural-gas" },
  {
    type: "collapse",
    name: "Filings",
    key: "filings",
    route: "/natural_gas/filings",
    icon: <ListIcon size="12px" />,
    component: <Filings />,
    noCollapse: true,
  },
];

export default routes;
